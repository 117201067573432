/** @jsx jsx */
import { Div, H3 } from '@cloudflare/elements'
import { Icon } from '@cloudflare/component-icon'
import { Toast } from '@cloudflare/component-toast'
import { jsx } from 'theme-ui'

const Aside = ({ type = 'info', title, children }) => {
  const toastType = { info: 'info', warn: 'error', encourage: 'success' }[type]

  const iconType = {
    info: 'info-sign',
    warn: 'exclamation-sign',
    encourage: 'ok-sign'
  }[type]

  return (
    <Toast type={toastType}>
      <Div position='absolute'>
        <Icon type={iconType} size={24} />
      </Div>
      {title && (
        <H3 display='inline-block' ml={4} my={0} fontSize={2}>
          {title}
        </H3>
      )}
      <Div
        ml={4}
        sx={{
          fontSize: 2,
          '& p:last-child': {
            margin: 0
          }
        }}
      >
        {children}
      </Div>
    </Toast>
  )
}

export default Aside
