import { Box } from '@cloudflare/component-box';
import { SingleColorLegend, ContinuousColorLegend, SeriesToggleLegend } from '@cloudflare/chart-color-legend';
import { useState } from 'react';
import * as React from 'react';
export default {
  Box,
  SingleColorLegend,
  ContinuousColorLegend,
  SeriesToggleLegend,
  useState,
  React
};