/** @jsx jsx */
import { jsx, Themed } from 'theme-ui'
import Tab from './Tab'
import Tabs from './Tabs'
import componentData from '../../data/component-info.json'

const bitbucketLinks = {
  Dashboard: path =>
    `https://bitbucket.cfdata.org/projects/FE/repos/stratus/browse/${path.replace(
      'stratus/',
      ''
    )}`,
  Teams: path =>
    `https://bitbucket.cfdata.org/projects/EA/repos/access-frontend/browse/${path.replace(
      'access-frontend/',
      ''
    )}`,
  Radar: path =>
    `https://bitbucket.cfdata.org/projects/FE/repos/my-internet/browse/${path.replace(
      'my-internet/',
      ''
    )}`
}

const ComponentUsage = ({ frontmatter }) => {
  if (!frontmatter || !frontmatter.packageName) return null

  const data = componentData[frontmatter.packageName]

  if (frontmatter.packageName && !data) {
    console.warn(`Missing ${frontmatter.packageName} usage information`)
    return null
  }

  return (
    <div>
      <Themed.h2
        sx={{
          fontWeight: 600
        }}
      >
        Product Usage
      </Themed.h2>
      <Tabs>
        {Object.keys(data.usage).map((app, index) => (
          <Tab key={index} title={app} count={data.usage[app].length}>
            <ul sx={{ p: 0, listStyle: 'none' }}>
              {data.usage[app].map(path => (
                <li key={path} sx={{ mb: 1 }}>
                  <a href={bitbucketLinks[app](path)}>
                    <code>
                      {/* TODO: handle path rendering elsewhere */}
                      {path
                        .replace('stratus/src/apps/dash/react/', '')
                        .replace('my-internet/src/', '')
                        .replace('access-frontend/src/', '')}
                    </code>
                  </a>
                </li>
              ))}
            </ul>
          </Tab>
        ))}
      </Tabs>
    </div>
  )
}

export default ComponentUsage
