import {
  Dropdown,
  DropdownLink,
  DropdownSeparator
} from '@cloudflare/component-dropdown'
import { Button, ButtonGroup } from '@cloudflare/component-button'
import React, { useRef, useState } from 'react'

const DropdownExample = () => {
  const [dropdown1Open, setDropdown1Open] = useState(false)
  const dropdown1OpenRef = useRef(false)
  const [dropdown2Open, setDropdown2Open] = useState(false)
  const dropdown2OpenRef = useRef(false)

  return (
    <ButtonGroup>
      <Button type='primary' onClick={() => setDropdown1Open(true)}>
        Open Dropdown 1
      </Button>
      {dropdown1Open && (
        <Dropdown
          onClose={() => {
            if (dropdown1Open !== dropdown1OpenRef.current) {
              dropdown1OpenRef.current = true
            } else {
              dropdown1OpenRef.current = false
              setDropdown1Open(false)
            }
          }}
        >
          <DropdownLink to='/foo'>Link to /foo</DropdownLink>
          <DropdownLink to='/bar'>Link to /bar</DropdownLink>
          <DropdownSeparator />
          <DropdownLink to='/baz'>Link to /baz</DropdownLink>
          <DropdownLink to='/bat'>Link to /bat</DropdownLink>
          <DropdownLink disabled to='/bad'>
            Disabled dropdown link
          </DropdownLink>
        </Dropdown>
      )}
      <Button type='success' onClick={() => setDropdown2Open(true)}>
        Open Dropdown 2
      </Button>
      {dropdown2Open && (
        <Dropdown
          align='right'
          onClose={() => {
            if (dropdown2Open !== dropdown2OpenRef.current) {
              dropdown2OpenRef.current = true
            } else {
              dropdown2OpenRef.current = false
              setDropdown2Open(false)
            }
          }}
        >
          <DropdownLink to='/foo'>Link to /foo</DropdownLink>
          <DropdownLink to='/bar'>Link to /bar</DropdownLink>
          <DropdownSeparator />
          <DropdownLink to='/baz'>Link to /baz</DropdownLink>
          <DropdownLink to='/bat'>Link to /bat</DropdownLink>
        </Dropdown>
      )}
    </ButtonGroup>
  )
}

export default DropdownExample
