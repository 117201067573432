import {
  Table,
  TableBody,
  TableHeader,
  TableCell,
  TableRow,
  ExpandableTableRow
} from '@cloudflare/component-expandable-table'
import { createComponent } from '@cloudflare/style-container'
import React from 'react'

const ExpandableTableExample = () => {
  const [active, setActive] = React.useState(null)
  const handleClick = id => {
    if (active === id) {
      setActive(null)
    } else {
      setActive(id)
    }
  }
  const rows = [
    {
      id: 1,
      puppy: 'Mr. Max Really LongName',
      kitten: 'Mittens',
      dinosaur: 'Rexy',
      monkey: 'Koko'
    },
    {
      id: 2,
      puppy: 'Pebbles',
      kitten: 'Bonbon',
      dinosaur: 'Barney',
      monkey: 'George'
    }
  ]
  const TableDetailsSection = createComponent(() => ({
    backgroundColor: '#ffffff',
    paddingLeft: '1.5em',
    paddingRight: '1.5em',
    paddingTop: '1.5em',
    paddingBottom: '1.5em'
  }))
  return (
    <Table>
      <TableHeader>
        <TableCell width='25%'>Puppies</TableCell>
        <TableCell width='25%'>Kittens</TableCell>
        <TableCell width='25%'>Dinosaur</TableCell>
        <TableCell width='25%'>Monkey</TableCell>
      </TableHeader>
      <TableBody>
        {rows.map(row => (
          <ExpandableTableRow key={row.id} active={active === row.id}>
            <TableRow onClick={() => handleClick(row.id)}>
              <TableCell width='25%' textEllipsis>
                {row.puppy}
              </TableCell>
              <TableCell width='25%'>{row.kitten}</TableCell>
              <TableCell width='25%'>{row.dinosaur}</TableCell>
              <TableCell width='25%'>{row.monkey}</TableCell>
            </TableRow>
            {active === row.id && (
              <TableDetailsSection>
                {`Hey there! Did you know dogs, cats, dinosurs and monkeys can't
                eat chocolate. What a shame!`}
              </TableDetailsSection>
            )}
          </ExpandableTableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default ExpandableTableExample
