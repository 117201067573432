/** @jsx jsx */
import { Div } from '@cloudflare/elements'
import Aside from './Aside'
import { jsx } from 'theme-ui'

const CaptionCard = ({ type = 'info', title = 'title', content, children }) => {
  return (
    <Div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 0,
        minWidth: '320px',
        maxWidth: '400px',
        overflow: 'hidden'
      }}
    >
      <Div
        sx={{
          backgroundColor: type === 'warn' ? 'red.9' : type === 'encourage' ? 'green.9' : '',
          p: 3,
          borderRadius: '5px'
        }}
      >
        {children}
      </Div>
      <Div
        sx={{
          mt: 1
        }}
      >
        <Aside type={type} title={title}>
          {content ? content : ''}
        </Aside>
      </Div>
    </Div>
  )
}

export default CaptionCard
