/** @jsx jsx */
import { jsx } from 'theme-ui'

const ContentCard = ({ children, destination, heading, description }) => {
  return (
    <div
      className='ContentCard'
      sx={{
        borderTop: '1px solid',
        // borderColor: 'gray.7',
        borderColor: 'decorative',
        minHeight: '80px',
        my: 4,
        pt: 4,
        display: ['flex', 'flex', 'grid'],
        flexDirection: 'column',
        gridTemplateColumns: '1fr 2fr',
        columnGap: '80px',
        ':last-child': {
          borderBottom: 0
        }
      }}
    >
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <h2
          sx={{
            fontSize: 5,
            fontWeight: 600,
            marginBlockEnd: 0,
            marginBlockStart: 0
          }}
        >
          {heading}
        </h2>
        {children}
      </div>
      <div
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: ['column', 'column', 'row'],
          rowGap: ['16px', '16px', '0px'],
          alignItems: 'baseline'
          // fontSize: 4
        }}
      >
        <p
          className='ContentCardParagraph'
          sx={{
            maxWidth: '40ch !important',
            marginBlockEnd: 0,
            marginBlockStart: 0
          }}
        >
          {description}
        </p>
        <a
          href={destination}
          sx={{
            textDecoration: 'none',
            borderBottom: '1px dashed',
            borderColor: 'decorativeContrast',
            pb: 1,
            ':after': {
              content: `' →'`,
              color: 'decorativeContrast'
            },
            ':hover': {
              borderBottom: '1px solid',
              borderColor: 'linkHover',
              color: 'linkHover',
              ':after': {
                color: 'linkHover'
              }
            }
          }}
        >
          View {heading}
        </a>
      </div>
    </div>
  )
}

export default ContentCard
