import { H4, Div, Table, Tr, Td, Th, P, Span, Code } from '@cloudflare/elements'
import { variables as theme } from '@cloudflare/style-const'
import React from 'react'

const Scale = () => {
  return (
    <>
      <Table mb={4} width={1} style={{ borderCollapse: 'collapse' }}>
        <Tr>
          {theme.fontSizes.map((item, i) => (
            <Th
              key={i}
              borderColor='gray.7'
              borderBottom='1px solid'
              fontWeight={400}
              pb={1}
              borderLeft={i !== 0 ? '1px solid' : null}
              textAlign='center'
              color='gray.4'
            >
              {i}
            </Th>
          ))}
        </Tr>
        <Tr>
          {theme.fontSizes.map((item, i) => (
            <Td
              key={i}
              lineHeight={1}
              fontSize={`${item}px`}
              fontWeight={700}
              borderBottom='1px solid'
              borderColor='gray.7'
              borderLeft={i !== 0 ? '1px solid' : null}
              textAlign='center'
              style={{ verticalAlign: 'baseline' }}
            >
              Aa
            </Td>
          ))}
        </Tr>
        <Tr>
          {theme.fontSizes.map((item, i) => (
            <Td
              key={i}
              pt={1}
              borderColor='gray.7'
              borderLeft={i !== 0 ? '1px solid' : null}
              textAlign='center'
              color='gray.4'
            >
              {item}px
            </Td>
          ))}
        </Tr>
      </Table>
      <Div pt={5}>
        <H4>Annotated Scale</H4>
        <Div style={{ overflowX: 'scroll' }}>
          <Table style={{ borderSpacing: 0, borderCollapse: 'collapse' }}>
            {theme.fontSizes.map((item, i) => (
              <Tr key={i} style={{ verticalAlign: 'top' }}>
                <Td pr={3} borderBottom='1px solid' pt={4} borderColor='gray.7'>
                  <Span fontSize={2} display='block' fontWeight={800} mb={1}>
                    {item}px
                  </Span>
                </Td>
                <Td
                  pr={5}
                  borderBottom='1px solid'
                  borderColor='gray.7'
                  pt={4}
                  pb={4}
                >
                  <Code
                    backgroundColor='transparent'
                    fontSize={1}
                    mb={1}
                    display='block'
                  >
                    theme.fontSizes[{i}]
                  </Code>
                  <Code
                    backgroundColor='transparent'
                    fontSize={1}
                    display='block'
                  >
                    fontSize=&#123;{i}&#125;
                  </Code>
                </Td>
                <Td
                  pr={5}
                  fontSize={2}
                  borderBottom='1px solid'
                  py={4}
                  borderColor='gray.7'
                >
                  <P width='30em' mt={0} mb={0}>
                    {i === 0 && (
                      <Span>
                        Used for labels in analytics. Should be avoided for UI
                        copy.
                      </Span>
                    )}
                    {i === 1 && (
                      <Span>
                        Used in progress stepper, footers, sidebars, and more.
                        Can be necessary for high information density
                        interfaces, but should be avoided for important copy.
                      </Span>
                    )}
                    {i === 2 && (
                      <Span>
                        Form labels, help text, inline links, footer and sidebar
                        copy.
                      </Span>
                    )}
                    {i === 3 && (
                      <Span>Default body copy size. Often used for links.</Span>
                    )}
                    {i === 4 && (
                      <Span>
                        Card and promo card titles. Generally set to 500-700
                        font weight.
                      </Span>
                    )}
                    {i === 5 && (
                      <Span>
                        Card, promo card, and form titles. Generally set to
                        500-700 font weight. Can be used on mobile, tablet, and
                        desktop.
                      </Span>
                    )}
                    {i === 8 && (
                      <Span>
                        Marketing titles and large numbers. Line height should
                        be set to 1 or 1.25. Font-weights 400-700 are okay to
                        use. Can be used on tablet and desktop.
                      </Span>
                    )}
                    {i === 9 && (
                      <Span>
                        Marketing titles and large numbers. Line height should
                        be set to 1 or 1.25. Font-weights 400-700 are okay to
                        use. Should be used for desktop only.
                      </Span>
                    )}
                  </P>
                </Td>
                <Td
                  borderBottom='1px solid'
                  borderColor='gray.7'
                  style={{ verticalAlign: 'middle' }}
                  py={3}
                >
                  <P
                    fontSize={item + 'px'}
                    mb={0}
                    mt={0}
                    lineHeight={item < 32 ? 1.5 : 1.25}
                    fontWeight={500}
                    style={{ whiteSpace: 'nowrap' }}
                    textStyle='clipped'
                  >
                    <Span display='block' fontWeight={700}>
                      ABCDEFGHIJKLMNOPQRSTUVWXYZ
                    </Span>
                    <Span display='block' fontWeight={500}>
                      abcdefghijklmnopqrstuvwxyz
                    </Span>
                    <Span display='block' fontWeight={400}>
                      1234567890!@#$%^&amp;*()
                    </Span>
                  </P>
                </Td>
              </Tr>
            ))}
          </Table>
        </Div>
      </Div>
    </>
  )
}

export default Scale
