import React from 'react'
import { variables as theme } from '@cloudflare/style-const'
import { Div, Dl, Dt, Dd, Span } from '@cloudflare/elements'

const ColorsGrid = ({ ...props }) => (
  <Div
    display='grid'
    gridTemplateColumns='repeat(auto-fill, minmax(15rem, 1fr))'
    gridAutoRows='1fr'
    gridGap='16px'
  >
    {props.data.map(color => (
      <Div
        key={color.color}
        color={color.contrastWithWhite >= 4.5 ? 'white' : 'black'}
      >
        <Div borderRadius={2} bg={color.color} overflow='hidden'>
          <Div p={3}>
            <Dl mt={0} display='flex' flexWrap='wrap'>
              <Dt mt={0} width={1} display='flex' fontWeight={700} fontSize={0}>
                <Span fontSize={4}>{color.color}</Span>
              </Dt>
              <Dd fontSize={1} ml={0} mt={2} lineHeight={1.5}>
                <Span display='block'>{color.rgb}</Span>
                <Span display='block'>{color.name}</Span>
                <Span ml='auto'>{color.themeRef}</Span>
              </Dd>
            </Dl>

            <Div display='flex'>
              <Div mr={3}>
                <Span
                  fontSize={0}
                  px={1}
                  py={1}
                  borderRadius={2}
                  bg='white'
                  color='black'
                  fontWeight={700}
                >
                  {color.contrastWithWhite}
                </Span>
                <Span fontSize={0} p={1} color='white' fontWeight={700}>
                  Aa
                </Span>
              </Div>

              <Div>
                <Span
                  fontSize={0}
                  px={1}
                  py={1}
                  borderRadius={2}
                  bg='black'
                  color='white'
                  fontWeight={700}
                >
                  {color.contrastWithBlack}
                </Span>
                <Span fontSize={0} p={1} color='black' fontWeight={700}>
                  Aa
                </Span>
              </Div>
            </Div>

            <Div display='flex' height={16} mt={3} width={1}>
              {color.contrastPairs.map(({ name, value }) => (
                <Div
                  key={name}
                  title={name}
                  mr={1}
                  p={2}
                  style={{
                    width: '16px',
                    height: '16px',
                    cursor: 'pointer',
                    borderRadius: theme.radii[1]
                  }}
                  bg={value}
                />
              ))}
            </Div>
          </Div>

          <Div display='flex' color={color.color} bg='white'>
            {color.contrastPairs
              .filter(
                ({ name }) =>
                  name.includes('black') ||
                  name.includes('white') ||
                  name.includes('gray')
              )
              .map(({ name, value }) => (
                <Div
                  key={name}
                  title={name}
                  flex='1'
                  textAlign='center'
                  bg={value}
                  p={2}
                  fontSize={1}
                >
                  Aa
                </Div>
              ))}
          </Div>
        </Div>
      </Div>
    ))}
  </Div>
)

export default ColorsGrid
