import { P } from '@cloudflare/elements';
import { Form } from '@cloudflare/component-form';
import { Label } from '@cloudflare/component-label';
import { Input } from '@cloudflare/component-input';
import * as React from 'react';
export default {
  P,
  Form,
  Label,
  Input,
  React
};