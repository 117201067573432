/** @jsx jsx */
import { jsx } from 'theme-ui'

const DetailedCard = ({ children, description, heading }) => {
  return (
    <div
      className='DetailedCard'
      sx={{
        borderTop: '1px solid',
        borderColor: 'decorative',
        minHeight: '80px',
        my: 4,
        pt: 4,
        display: 'grid',
        gridTemplateColumns: '2fr 2fr',
        columnGap: '80px',
        ':last-child': {
          borderBottom: 0
        }
      }}
    >
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <h2
          sx={{
            fontSize: 5,
            fontWeight: 600,
            marginBlockEnd: 0,
            marginBlockStart: 0,
            mb: description ? '3' : ''
          }}
        >
          {heading}
        </h2>
        {description}
      </div>
      <div
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <div
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'baseline'
            // fontSize: 4
          }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default DetailedCard
