/** @jsx jsx */
import { Label } from '@cloudflare/component-label'
import { jsx } from 'theme-ui'
import React, { useState } from 'react'

const Tabs = ({ children, style }) => {
  const [active, setActive] = useState(0)

  return (
    <div sx={{ mb: 4, ...style }}>
      <ul
        sx={{
          display: 'flex',
          alignItems: 'center',
          listStyle: 'none',
          m: 0,
          mb: 3,
          p: 0
        }}
      >
        {React.Children.map(children, (child, index) => (
          <li
            onClick={() => setActive(index)}
            sx={{
              p: 2,
              mr: 3,
              borderBottom: '3px solid',
              borderColor: index === active ? 'blue.5' : 'transparent',
              cursor: 'pointer',
              '&:hover': {
                borderColor: index === active ? 'blue.5' : 'gray.9'
              }
            }}
          >
            {child.props.title}
            {child.props.count ? (
              <Label ml={2} hue='blue'>
                {child.props.count}
              </Label>
            ) : null}
          </li>
        ))}
      </ul>
      {React.Children.map(children, (child, index) =>
        index === active ? child : null
      )}
    </div>
  )
}

export default Tabs
