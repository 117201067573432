import { Div, Dl, Dt, Dd, Span } from '@cloudflare/elements'
import { variables } from '@cloudflare/style-const'
import React from 'react'

const getPropertyValue = (obj1, dataToRetrieve) => {
  return dataToRetrieve
    .split('.') // split string based on `.`
    .reduce(function (o, k) {
      return o && o[k] // get inner property if `o` is defined else get `o` and return
    }, obj1) // set initial value as object
}

const ColorCard = ({ color }) => {
  return (
    <Div mr={2} width={1}>
      <Div borderRadius={2} pt={3} px={3} pb={6} bg={color}>
        <Dl mt={0} display='flex' flexWrap='wrap'>
          <Dt mt={0} width={1} display='flex' fontWeight={700} fontSize={0}>
            <Span fontSize={4}>
              {getPropertyValue(variables.colors, color)}
            </Span>
          </Dt>
        </Dl>
        <Dd fontSize={1} ml={0} mt={2} lineHeight={1.5}>
          <Span display='block'>{color}</Span>
        </Dd>
      </Div>
    </Div>
  )
}

const Categorical = () => (
  <Div
    display='grid'
    gridTemplateColumns='repeat(auto-fill, minmax(15rem, 1fr))'
    gridAutoRows='1fr'
    gridGap='16px'
    backgroundColor='none'
  >
    {variables.colors.categorical.map((_, i) => (
      <ColorCard key={i} color={`categorical.${i}`} />
    ))}
  </Div>
)

export default Categorical
