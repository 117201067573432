import { Modal, ModalHeader, ModalTitle, ModalClose, ModalBody, ModalFooter, ModalActions, ModalInfo } from '@cloudflare/component-modal';
import { Button } from '@cloudflare/component-button';
import { Box } from '@cloudflare/component-box';
import * as React from 'react';
export default {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalClose,
  ModalBody,
  ModalFooter,
  ModalActions,
  ModalInfo,
  Button,
  Box,
  React
};