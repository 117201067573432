/** @jsx jsx */
import { Label } from '@cloudflare/component-label'
import { jsx, Themed } from 'theme-ui'
import packages from '../../../package.json'

const BitbucketIcon = ({ ...styles }) => (
  <svg
    sx={styles}
    height='24'
    preserveAspectRatio='xMidYMid'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='-0.9662264221278978 -0.5824607696358868 257.93281329857973 230.8324730411935'
  >
    <linearGradient id='a' x1='108.633%' x2='46.927%' y1='13.818%' y2='78.776%'>
      <stop offset='.18' stopColor='#0052cc' />
      <stop offset='1' stopColor='#2684ff' />
    </linearGradient>
    <g fill='none'>
      <path d='M101.272 152.561h53.449l12.901-75.32H87.06z' />
      <path
        d='M8.308 0A8.202 8.202 0 0 0 .106 9.516l34.819 211.373a11.155 11.155 0 0 0 10.909 9.31h167.04a8.202 8.202 0 0 0 8.201-6.89l34.82-213.752a8.202 8.202 0 0 0-8.203-9.514zm146.616 152.768h-53.315l-14.436-75.42h80.67z'
        fill='#2684ff'
      />
      <path
        d='M244.61 77.242h-76.916l-12.909 75.36h-53.272l-62.902 74.663a11.105 11.105 0 0 0 7.171 2.704H212.73a8.196 8.196 0 0 0 8.196-6.884z'
        fill='url(#a)'
      />
    </g>
  </svg>
)

const FigmaIcon = ({ ...styles }) => (
  <svg
    sx={styles}
    height='24'
    viewBox='0 0 200 300'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M50 300c27.6 0 50-22.4 50-50v-50H50c-27.6 0-50 22.4-50 50s22.4 50 50 50z'
      fill='#0acf83'
    />
    <path
      d='M0 150c0-27.6 22.4-50 50-50h50v100H50c-27.6 0-50-22.4-50-50z'
      fill='#a259ff'
    />
    <path
      d='M0 50C0 22.4 22.4 0 50 0h50v100H50C22.4 100 0 77.6 0 50z'
      fill='#f24e1e'
    />
    <path
      d='M100 0h50c27.6 0 50 22.4 50 50s-22.4 50-50 50h-50z'
      fill='#ff7262'
    />
    <path
      d='M200 150c0 27.6-22.4 50-50 50s-50-22.4-50-50 22.4-50 50-50 50 22.4 50 50z'
      fill='#1abcfe'
    />
  </svg>
)

const Metadata = ({
  frontmatter: { updated, status, codeURL, designURL, packageName },
  title
}) => {
  const statusMapping = { deprecated: 'red' }

  let version
  try {
    const devDependencies = packages.devDependencies[packageName]?.match(
      /[~^]?([\dvx*]+(?:[-.](?:[\dx*]+|alpha|beta))*)/
    )
    const dependencies = packages.dependencies[packageName]?.match(
      /[~^]?([\dvx*]+(?:[-.](?:[\dx*]+|alpha|beta))*)/
    )
    packageName &&
      (version = devDependencies ? devDependencies[1] : dependencies[1])
  } catch {
    packageName &&
      console.warn(
        `Could not parse version from package.json of ${packageName}`
      )
  }

  return (
    <div>
      <div
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        {title ? <Themed.h1>{title}</Themed.h1> : null}
        {status ? (
          <div sx={{ pl: [2, 2, 3] }}>
            <Label hue={statusMapping[status] || 'gray'} children={status} />
          </div>
        ) : null}
      </div>
      {updated || version || codeURL || designURL ? (
        <div
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            fontSize: 2
          }}
        >
          <div
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {version ? (
              <span
                sx={{
                  mb: 2
                }}
              >
                v{version}
              </span>
            ) : null}
            {updated ? (
              <span
                sx={{
                  mb: 2
                }}
              >
                Last updated{' '}
                {new Date(updated).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </span>
            ) : null}
          </div>

          <div
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {designURL ? (
              <a
                href={designURL}
                rel='noopener noreferrer'
                target='_blank'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  variant: 'links.plain',
                  mb: 2
                }}
              >
                {/* <FigmaIcon mr={2} /> */}
                Open in Figma
              </a>
            ) : null}
            {codeURL ? (
              <a
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  variant: 'links.plain'
                }}
                href={codeURL}
                rel='noopener noreferrer'
                target='_blank'
              >
                {/* <BitbucketIcon mr={2} /> */}
                View source
              </a>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Metadata
