/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box } from '@cloudflare/component-box'
import { Button, ButtonGroup } from '@cloudflare/component-button'
import { Form, FormFooter, FormLabel } from '@cloudflare/component-form'
import { Input, LabeledInput } from '@cloudflare/component-input'
import { Radio, RadioGroup } from '@cloudflare/component-radio'
import { Textarea } from '@cloudflare/component-textarea'
import marked from 'marked'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Frame from 'react-frame-component'
import { Tab, Tabs } from '../../../components/mdx/index'
import emailTemplate from '../../../data/email-template.json'

const extractHTML = (str, start, end) => {
  var startindex = str.indexOf(start)
  var endindex = str.indexOf(end, startindex)
  if (startindex !== -1 && endindex !== -1 && endindex > startindex)
    return str.substring(startindex, endindex + end.length)
}

const Preview = () => {
  const [heading, setHeading] = useState('')
  const [content, setContent] = useState('')

  const [CTAs, setCTAs] = useState('1')
  const [primaryCTALabel, setPrimaryCTALabel] = useState('')
  const [primaryCTALink, setPrimaryCTALink] = useState('')
  const [secondaryCTALabel, setSecondaryCTALabel] = useState('')
  const [secondaryCTALink, setSecondaryCTALink] = useState('')

  const [disclaimer, setDisclaimer] = useState('')

  const [copyStatus, setCopyStatus] = useState(false)
  useEffect(() => {
    if (copyStatus) {
      setTimeout(() => {
        setCopyStatus(false)
      }, 3000)
    }
  }, [copyStatus])

  // Auto save to localStorage preventing page failure
  const firstRender = useRef(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!firstRender.current) {
        firstRender.current = true
        const stored = JSON.parse(window.localStorage.getItem('emailGenerator'))
        if (stored && stored.timestamp) {
          if (Date.now() - stored.timestamp > 1000 * 60 * 60 * 24) {
            window.localStorage.removeItem('emailGenerator')
          } else {
            setHeading(stored.heading)
            setContent(stored.content)
            setCTAs(stored.CTAs)
            setPrimaryCTALabel(stored.primaryCTALabel)
            setPrimaryCTALink(stored.primaryCTALink)
            setSecondaryCTALabel(stored.secondaryCTALabel)
            setSecondaryCTALink(stored.secondaryCTALink)
            setDisclaimer(stored.disclaimer)
          }
        }
      } else {
        window.localStorage.setItem(
          'emailGenerator',
          JSON.stringify({
            timestamp: Date.now(),
            heading,
            content,
            CTAs,
            primaryCTALabel,
            primaryCTALink,
            secondaryCTALabel,
            secondaryCTALink,
            disclaimer
          })
        )
      }
    }
  }, [
    firstRender.current,
    heading,
    content,
    CTAs,
    primaryCTALabel,
    primaryCTALink,
    secondaryCTALabel,
    secondaryCTALink,
    disclaimer
  ])

  const frameRef = useRef()
  const [frameHeight, setFrameHeight] = useState(589)
  useEffect(() => {
    const height = frameRef.current?.clientHeight
    if (frameHeight !== height) {
      setFrameHeight(height)
    }
  }, [content.length, frameHeight])

  const __html = useMemo(() => {
    const mapping = [
      'templateWithoutCTA',
      'templateWith1CTA',
      'templateWith2CTAs'
    ]

    return emailTemplate[mapping[CTAs]]
      .replace('{{HEADING}}', heading.length ? heading : 'Heading placeholder')
      .replace(
        '{{BODY}}',
        content.length
          ? marked(content)
          : marked(`Dear {{name}},\n\nThis is content placeholder.`)
      )
      .replace(
        '{{CTA_1_LABEL}}',
        primaryCTALabel.length ? primaryCTALabel : 'Primary CTA'
      )
      .replace('{{CTA_1_HREF}}', `https://${primaryCTALink}`)
      .replace(
        '{{CTA_2_LABEL}}',
        secondaryCTALabel.length ? secondaryCTALabel : 'Secondary CTA'
      )
      .replace('{{CTA_2_HREF}}', `https://${secondaryCTALink}`)
      .replace(
        '{{LEGAL}}',
        disclaimer.length
          ? marked.parseInline(disclaimer)
          : marked.parseInline(
              'If you believe you received this email in error, contact [support@cloudflare.com](mailto:support@cloudflare.com)'
            )
      )
  }, [
    heading,
    content,
    CTAs,
    primaryCTALabel,
    primaryCTALink,
    secondaryCTALabel,
    secondaryCTALink,
    disclaimer
  ])

  const handleCopy = e => {
    e.preventDefault()
    if (heading && content && primaryCTALabel && primaryCTALink) {
      navigator.clipboard.writeText(__html)
      setCopyStatus(true)
    }
  }

  return (
    <>
      <Form layout='vertical' border={false} onSubmit={handleCopy}>
        <Tabs style={{ mb: 0 }}>
          <Tab title='Content'>
            <FormLabel color='theme.colors.text'>Heading*</FormLabel>
            <Input
              required
              id='heading'
              name='heading'
              value={heading}
              onChange={({ target: { value } }) => setHeading(value)}
            />
            <FormLabel color='theme.colors.text'>
              Content* (markdown support)
            </FormLabel>
            <Textarea
              required
              name='content'
              value={content}
              onChange={({ target: { value } }) => setContent(value)}
              rows={6}
            />
          </Tab>

          <Tab title='Call to Action'>
            <FormLabel color='theme.colors.text'>CTA type*</FormLabel>
            <RadioGroup
              color='theme.colors.text'
              value={CTAs}
              onChange={setCTAs}
            >
              <Radio label='No CTA' name='0-cta' value='0' />
              <Radio label='Primary CTA' name='1-cta' value='1' />
              <Radio
                label='Primary CTA and Secondary CTA'
                name='2-cta'
                value='2'
              />
            </RadioGroup>
            {(CTAs === '1' || CTAs === '2') && (
              <Box
                display='flex'
                justifyContent='space-between'
                flexWrap='wrap'
                marginTop={3}
              >
                <Box flexGrow={1} marginRight={2}>
                  <FormLabel color='theme.colors.text'>
                    Primary CTA Label*
                  </FormLabel>
                  <Input
                    required
                    name='primaryCTALabel'
                    value={primaryCTALabel}
                    onChange={({ target: { value } }) =>
                      setPrimaryCTALabel(value)
                    }
                  />
                </Box>
                <Box flexGrow={1} marginLeft={2}>
                  <FormLabel color='theme.colors.text'>
                    Primary CTA Link*
                  </FormLabel>
                  <LabeledInput
                    label='https://'
                    required
                    name='primaryCTALink'
                    value={primaryCTALink}
                    onChange={({ target: { value } }) =>
                      setPrimaryCTALink(value)
                    }
                  />
                </Box>
              </Box>
            )}
            {CTAs === '2' && (
              <Box
                display='flex'
                justifyContent='space-between'
                flexWrap='wrap'
                marginTop={3}
              >
                <Box flexGrow={1} marginRight={2}>
                  <FormLabel color='theme.colors.text'>
                    Secondary CTA Label*
                  </FormLabel>
                  <Input
                    required
                    name='secondaryCTALabel'
                    value={secondaryCTALabel}
                    onChange={({ target: { value } }) =>
                      setSecondaryCTALabel(value)
                    }
                  />
                </Box>
                <Box flexGrow={1} marginLeft={2}>
                  <FormLabel color='theme.colors.text'>
                    Secondary CTA Link*
                  </FormLabel>
                  <LabeledInput
                    label='https://'
                    required
                    name='secondaryCTALink'
                    value={secondaryCTALink}
                    onChange={({ target: { value } }) =>
                      setSecondaryCTALink(value)
                    }
                  />
                </Box>
              </Box>
            )}
          </Tab>

          <Tab title='Legal'>
            <FormLabel color='theme.colors.text'>
              Disclaimer (markdown support)
            </FormLabel>
            <Input
              name='disclaimer'
              value={disclaimer}
              onChange={({ target: { value } }) => setDisclaimer(value)}
            />
          </Tab>
        </Tabs>
        <FormFooter border={false}>
          <ButtonGroup spaced>
            <Button
              type='danger'
              inverted
              onClick={() => {
                setHeading('')
                setContent('')
                setCTAs('1')
                setPrimaryCTALabel('')
                setPrimaryCTALink('')
                setSecondaryCTALabel('')
                setSecondaryCTALink('')
                setDisclaimer('')
              }}
            >
              Clear
            </Button>
            <Button submit type='primary'>
              {copyStatus ? 'Copied!' : 'Copy HTML'}
            </Button>
          </ButtonGroup>
        </FormFooter>
      </Form>
      <Frame
        style={{ marginTop: '32px', width: '100%', height: `${frameHeight}px` }}
      >
        <div ref={frameRef} dangerouslySetInnerHTML={{ __html }} />
      </Frame>
    </>
  )
}

export default Preview
