import { Table, Tbody, Tr, Td } from '@cloudflare/elements'
import React from 'react'

const THEME_FONT_WEIGHTS = {
  weightLight: 300,
  weightNormal: 400,
  weightSemiBold: 600,
  weightBold: 700
}

const FontWeight = () => {
  return (
    <Table style={{ borderCollapse: 'collapse' }}>
      <Tbody>
        <Tr fontSize={[6, 8, 9]}>
          {Object.entries(THEME_FONT_WEIGHTS).map((value, i) => {
            return (
              <Td
                px={2}
                key={i}
                borderLeft={i !== 0 ? '1px solid black' : null}
                borderColor='gray.7'
                textAlign='center'
                fontWeight={value[1]}
              >
                Aa
              </Td>
            )
          })}
        </Tr>
        <Tr fontSize={1}>
          {Object.entries(THEME_FONT_WEIGHTS).map((value, i) => {
            return (
              <Td
                p={2}
                key={i}
                borderTop='1px solid'
                borderColor='gray.7'
                borderLeft={i !== 0 ? '1px solid black' : null}
                fontWeight={value[1]}
              >
                Cache rules everything around me. The network is the computer®.
              </Td>
            )
          })}
        </Tr>
        <Tr>
          {Object.entries(THEME_FONT_WEIGHTS).map((value, i) => {
            return (
              <Td
                key={i}
                borderTop='1px solid black'
                borderLeft={i !== 0 ? '1px solid black' : null}
                borderColor='gray.7'
                textAlign='center'
                fontSize={1}
              >
                {value[1]}
              </Td>
            )
          })}
        </Tr>
      </Tbody>
    </Table>
  )
}

export default FontWeight
