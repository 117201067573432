import { variables } from '@cloudflare/style-const'

const common = {
  button: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: variables.space[4],
    px: variables.space[3],
    py: variables.space[2],
    fontFamily: variables.fontFamily,
    // When a button has both label and icon, a <span> needs to be added around the label
    '& > svg:not(:only-child)': { ml: variables.space[2] }
  }
}

const theme = {
  // Breakpoints
  breakpoints: [
    variables.breakpoints.mobileWide, // .mobile is much too narrow?
    variables.breakpoints.tablet,
    variables.breakpoints.desktop
  ],
  // Typography
  fonts: { body: variables.fontFamily, heading: 'inherit' },
  fontSizes: variables.fontSizes,
  fontWeights: { body: 400, heading: 600, bold: 600 },
  lineHeights: { body: 1.5, heading: 1.25 },
  // Color modes
  config: { initialColorModeName: 'default', printColorModeName: 'light' },
  colors: {
    text: variables.colors.gray[0], // Marketing site has #222 while we only have true black?
    background: variables.colors.white,
    codeBackground: variables.colors.gray[9],
    primary: variables.colors.cfOrange,
    secondary: variables.colors.blue[4],
    linkHover: variables.colors.orange[5],
    decorativeAlphaZero: variables.colors.gray[9] + `00`,
    decorativeContrast: variables.colors.gray[4],
    decorative: variables.colors.gray[9],
    logotypeText: variables.colors.black,
    // The rest
    red: variables.colors.red,
    gold: variables.colors.gold,
    green: variables.colors.green,
    cyan: variables.colors.cyan,
    // blue: variables.colors.blue[9],
    blue: variables.colors.blue[4],
    indigo: variables.colors.indigo,
    violet: variables.colors.violet,
    pink: variables.colors.pink,
    gray: variables.colors.gray,
    modes: {
      dark: {
        text: variables.colors.gray[8],
        background: variables.colors.gray[0],
        codeBackground: variables.colors.gray[1],
        primary: variables.colors.cfOrange,
        secondary: variables.colors.blue[4],
        linkHover: variables.colors.orange[7],
        decorativeAlphaZero: variables.colors.gray[1] + `00`,
        decorativeContrast: variables.colors.gray[6],
        decorative: variables.colors.gray[1],
        logotypeText: variables.colors.white,
        // The rest
        red: variables.colors.red,
        orange: variables.colors.orange,
        gold: variables.colors.gold,
        green: variables.colors.green,
        cyan: variables.colors.cyan,
        blue: variables.colors.blue[6],
        indigo: variables.colors.indigo,
        violet: variables.colors.violet,
        pink: variables.colors.pink,
        gray: variables.colors.gray
      }
    }
  },
  // Others
  space: variables.space,
  radii: variables.radii,
  transitions: { standard: '0.3s ease-in-out' },
  // Styles
  styles: {
    root: {
      fontFamily: 'body',
      fontSize: 3,
      lineHeight: 'body',
      fontWeight: 'body',
      backgroundColor: 'background',
      color: 'text'
    },
    pre: {
      '& > pre': {
        p: 3,
        overflowY: 'auto',
        '::-webkit-scrollbar': {
          height: '8px'
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: `decorative`,
          borderRadius: '8px'
        }
      }
    },
    code: {
      color: 'text',
      backgroundColor: 'codeBackground',
      '.comment,.prolog,.doctype,.cdata': {
        color: '#999988',
        fontStyle: 'italic'
      },
      '.namespace': {
        opacity: 0.7
      },
      '.string,.attr-value': {
        color: '#E3116C'
      },
      '.punctuation,.operator': {
        color: '#393A34'
      },
      '.entity,.url,.symbol,.number,.boolean,.variable,.constant,.property,.regex,.inserted':
        {
          color: '#36ACAA'
        },
      '.atrule,.keyword,.attr-name,.selector': {
        color: '#00A4DB'
      },
      '.function,.deleted,.tag': {
        color: '#D73A49'
      },
      '.function-variable': {
        color: '#6F42C1'
      },
      '.tag,.selector,.keyword': {
        color: '#00009F'
      },
      '.highlight': {
        background: 'hsla(0, 0%, 70%, .5)'
      },
      p: 3,
      borderRadius: 8
    },
    // Below are ordered according to https://www.gatsbyjs.com/plugins/gatsby-plugin-mdx/#mdxprovider, so we won't forget anything
    p: {
      marginBlockStart: 0,
      marginBlockEnd: 4
    },
    h1: {
      fontSize: 7,
      lineHeight: 'heading',
      fontWeight: 'heading',
      marginBlockStart: 0,
      marginBlockEnd: 4
    },
    h2: {
      fontSize: 5,
      lineHeight: 'heading',
      fontWeight: 'heading',
      marginBlockStart: 0,
      marginBlockEnd: 3
    },
    h3: {
      fontSize: 4,
      lineHeight: 'heading',
      fontWeight: 'heading',
      marginBlockStart: 0,
      marginBlockEnd: 3
    },
    h4: {
      lineHeight: 'heading',
      fontWeight: 'heading',
      marginBlockStart: 0,
      marginBlockEnd: 3
    },
    h5: {
      lineHeight: 'heading',
      fontWeight: 'heading',
      marginBlockStart: 0,
      marginBlockEnd: 3
    },
    h6: {
      lineHeight: 'heading',
      fontWeight: 'heading',
      marginBlockStart: 0,
      marginBlockEnd: 3
    },
    hr: {
      my: 4,
      border: 'none',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'decorative'
      // marginLeft: 3,
      // marginRight: 3
    },
    blockquote: {
      position: 'relative',
      '::before': {
        quotes: '"\\201C" "\\201D"',
        content: 'open-quote',
        position: 'absolute',
        top: '.05em',
        left: '-.8em',
        display: 'inline-block',
        fontFamily: 'Arial',
        fontWeight: 'heading',
        fontSize: 6,
        lineHeight: 1,
        height: '.5em',
        width: '.7em'
      },
      '::after': {
        position: 'absolute',
        content: '""',
        top: '1.5em',
        bottom: 0,
        left: '-1rem',
        width: 4,
        backgroundColor: 'decorative'
      }
    },
    ul: {
      paddingLeft: 4,
      marginBottom: 4
    },
    ol: {
      paddingLeft: 4,
      marginBottom: 4
    },
    li: {
      marginBottom: 1
    },
    // tr: { border: '1px solid', borderColor: 'decorative' },
    td: { borderBottom: '1px solid', borderColor: 'decorative' },
    th: { 'text-align': 'left' },
    a: {
      paddingBottom: 1,
      textDecoration: 'none',
      '&:hover': {
        color: 'linkHover'
      },
      '& > span': {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'linkHover'
      }
    }
  },
  // Variants
  buttons: {
    primary: {
      ...common.button,
      color: 'white',
      bg: 'secondary',
      '&:hover': { bg: variables.colors.blue[3] },
      '&:focus': { bg: variables.colors.blue[2] },
      '&:disabled': {
        cursor: 'not-allowed',
        opacity: 0.5,
        '&:hover': { bg: 'secondary' },
        '&:focus': { bg: 'secondary' }
      }
    },
    secondary: {
      ...common.button,
      color: variables.colors.gray[0],
      bg: variables.colors.gray[8],
      '&:hover': { bg: variables.colors.gray[6] },
      '&:focus': { bg: variables.colors.gray[5] },
      '&:disabled': {
        cursor: 'not-allowed',
        opacity: 0.5,
        '&:hover': { bg: variables.colors.gray[8] },
        '&:focus': { bg: variables.colors.gray[8] }
      }
    },
    tertiary: {
      ...common.button,
      color: 'white',
      bg: variables.colors.red[4],
      '&:hover': { bg: variables.colors.red[2] },
      '&:focus': { bg: variables.colors.red[0] },
      '&:disabled': {
        cursor: 'not-allowed',
        opacity: 0.5,
        '&:hover': { bg: variables.colors.red[4] },
        '&:focus': { bg: variables.colors.red[4] }
      }
    },
    ghost: {
      ...common.button,
      color: 'blue',
      bg: 'background',
      border: '1px solid',
      borderColor: 'blue',
      '&:hover': { color: 'background', bg: 'blue' },
      '&:focus': { color: 'background', bg: 'blue' },
      '&:disabled': {
        cursor: 'not-allowed',
        opacity: 0.5,
        '&:hover': { color: 'blue', bg: 'background' },
        '&:focus': { color: 'blue', bg: 'background' }
      }
    },
    sansOutline: {
      ...common.button,
      height: 'auto',
      px: 0,
      py: 0,
      color: 'text',
      bg: 'background',
      '&:hover span': {
        textDecoration: 'underline',
        color: 'linkHover'
      },
      // "&:focus": { color: "white", bg: variables.colors.orange[4] },
      '&:disabled': {
        cursor: 'not-allowed',
        opacity: 0.5,
        '&:hover': { color: 'secondary', bg: 'background' },
        '&:focus': { color: 'secondary', bg: 'background' }
      }
    },
    close: {},
    icon: {},
    menu: {}
  },
  links: {
    navigation: {
      display: 'inline-flex',
      px: 2,
      py: 1,
      textDecoration: 'none',
      '&:hover': {
        color: 'background',
        textDecoration: 'none',
        backgroundColor: 'blue',
        borderRadius: '4px'
      },
      '&.active': {
        color: 'background',
        textDecoration: 'none',
        backgroundColor: 'blue',
        borderRadius: '4px'
      }
    },
    plain: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
        color: 'linkHover'
      }
    },
    basic: {
      display: 'inline-flex',
      p: 1,
      textDecoration: 'none',
      '&[aria-current]:not([aria-current="false"])': {
        textDecoration: 'none',
        backgroundColor: 'blue',
        borderRadius: '4px'
      },
      '&:hover': {
        textDecoration: 'underline',
        color: 'linkHover'
      }
    }
  }
}

export default theme
