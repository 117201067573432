import { Box } from '@cloudflare/component-box'
import {
  Dropdown,
  List,
  ListItem,
  LabelItem,
  Link
} from '@cloudflare/component-header-dropdown'
import React, { useState } from 'react'
import { MemoryRouter } from 'react-router-dom'

// When the codes below are included in the markdown, the styles are not displayed correctly

const HeaderDropdownExample = () => {
  const [active, setActive] = useState(false)
  const [active2, setActive2] = useState(false)
  return (
    <MemoryRouter>
      <Box border='1px solid #EEE' height='61px'>
        <Dropdown
          active={active}
          onButtonClick={() => setActive(!active)}
          onOutsideClick={() => setActive(false)}
          onEsc={() => setActive(false)}
          buttonLabel='Click me'
        >
          <List>
            <LabelItem>Label</LabelItem>
            <ListItem>
              <Link to='/foo'>Link 1</Link>
            </ListItem>
            <ListItem>
              <Link to='/foo'>Link 2</Link>
            </ListItem>
            <ListItem showBorderTop>
              <Link to='/foo'>Link with top border</Link>
            </ListItem>
            <ListItem showBorderBottom>
              <Link to='/foo'>Link with bottom border</Link>
            </ListItem>
            <ListItem>
              <Link to='/foo'>Link 3</Link>
            </ListItem>
          </List>
        </Dropdown>
        <Dropdown
          active={active2}
          onButtonClick={() => setActive2(!active2)}
          onOutsideClick={() => setActive2(false)}
          onEsc={() => setActive2(false)}
          buttonLabel={<i>Label</i>}
          buttonTitle='Title'
        >
          <Box width='12em' height='5em' padding='1em'>
            Or hit ESC! ... or click outside.
          </Box>
        </Dropdown>
      </Box>
    </MemoryRouter>
  )
}

export default HeaderDropdownExample
