/** @jsx jsx */
import { Icon } from '@cloudflare/component-icon'
import { Themed } from '@theme-ui/mdx'
import Prism from '@theme-ui/prism'
import { Link } from 'gatsby'
import slugify from 'slugify'
import { jsx } from 'theme-ui'

const heading = Tag => props => {
  if (typeof props.children !== 'string') {
    console.warn(`Heading ${props.children.toString()} is not a string!`)
  }
  const slug = slugify(props.children.toString(), { lower: true })
  return (
    <Tag id={slug} {...props} sx={{ '&:hover > span': { opacity: 1 } }}>
      <span sx={{ opacity: 0, position: 'absolute' }}>
        <a
          sx={{
            position: 'absolute',
            right: '100%',
            paddingRight: 2,
            variant: 'links.plain'
          }}
          href={`#${slug}`}
          aria-hidden='true'
        >
          #​
        </a>
      </span>
      {props.children}
    </Tag>
  )
}

const components = {
  a: props => {
    const testInternalLink = props.href.match(
      /(https?:\/\/)?cloudflare.style(.*)/
    )

    if (props.href.startsWith('/') || testInternalLink) {
      return (
        <Themed.a
          as={Link}
          to={
            testInternalLink
              ? testInternalLink[testInternalLink.length - 1]
              : props.href
          }
          {...props}
        >
          <span>{props.children}</span>
        </Themed.a>
      )
    } else {
      return (
        <a {...props} target='_blank' rel='noopener noreferrer'>
          <span>{props.children}</span>
          {typeof props.children === 'string' && (
            <Icon type='external-link' sx={{ marginLeft: 1 }} />
          )}
        </a>
      )
    }
  },
  h1: () => null,
  h2: heading('h2'),
  h3: heading('h3'),
  h4: heading('h4'),
  h5: heading('h5'),
  h6: heading('h6'),
  code: Prism
}

export default components
