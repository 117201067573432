/** @jsx jsx */
import { Div } from '@cloudflare/elements'
import { jsx } from 'theme-ui'

const PreviewCard = ({ children }) => {
  return (
    <Div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: 'decorative',
        border: '1px solid',
        borderColor: 'decorative',
        borderRadius: '4px',
        padding: 2,
        my: 3,
        width: 'auto',
        fontSize: 1,
        fontWeight: 600,
        p: {
          marginBlockEnd: '0',
          height: '100%',
          width: '100%',
          maxWidth: 'unset'
        }
      }}
    >
      {children}
    </Div>
  )
}

export default PreviewCard
