import { Div, Button } from '@cloudflare/elements'
import { variables } from '@cloudflare/style-const'
import chroma from 'chroma-js'
import React, { useState } from 'react'
import ColorsGrid from './_Overview/_Grid'
import ColorsTable from './_Overview/_Table'

const THEME_COLORS = [
  'red',
  'orange',
  'gold',
  'green',
  'cyan',
  'blue',
  'indigo',
  'violet',
  'pink',
  'gray',
  'black',
  'white',
  'cfOrange'
]
const generatePairs = (hue, index) => {
  const pairs = []
  const color = variables.colors[hue][index]

  variables.colors[hue].forEach((hueColor, index) => {
    if (chroma.contrast(color, hueColor) >= 4.5) {
      pairs.push({
        name: `variables.colors.${hue}[${index}]`,
        value: hueColor
      })
    }
  })

  if (chroma.contrast(color, 'black') >= 4.5) {
    pairs.push({
      name: 'variables.colors.black',
      value: variables.colors.black
    })
  }

  if (hue !== 'gray') {
    variables.colors.gray.forEach((hueColor, index) => {
      if (chroma.contrast(color, hueColor) >= 4.5) {
        pairs.push({
          name: `variables.colors.gray[${index}]`,
          value: hueColor
        })
      }
    })
  }

  if (chroma.contrast(color, variables.colors.white) >= 4.5) {
    pairs.push({
      name: 'variables.colors.white',
      value: variables.colors.white
    })
  }

  return pairs
}
const flatColors = THEME_COLORS.reduce((acc, hue) => {
  const color = variables.colors[hue]

  if (Array.isArray(color)) {
    return [
      ...acc,
      ...color.map((hueColor, index) => ({
        name: `${hue}.${index}`,
        hue: hue,
        themeRef: `variables.colors.${hue}[${index}]`,
        color: hueColor,
        rgb: chroma(hueColor).css(),
        contrastWithWhite: chroma.contrast(hueColor, 'white').toFixed(2),
        contrastWithBlack: chroma.contrast(hueColor, 'black').toFixed(2),
        contrastPairs: generatePairs(hue, index)
      }))
    ]
  }

  return [
    ...acc,
    {
      name: hue,
      color: color,
      themeRef: `variables.colors.${hue}`,
      rgb: chroma(color).css(),
      contrastWithWhite: chroma.contrast(color, 'white').toFixed(2),
      contrastWithBlack: chroma.contrast(color, 'black').toFixed(2),
      contrastPairs: variables.colors.gray
        .map((value, index) => ({
          name: `variables.colors.gray[${index}]`,
          value: value
        }))
        .filter(({ value }) => chroma.contrast(color, value) >= 4.5)
    }
  ]
}, [])

const Overview = () => {
  const [viewMode, setViewMode] = useState(() => {
    return typeof window !== 'undefined'
      ? window.localStorage.getItem('category-component-view-mode')
      : 'table'
  })

  return (
    <>
      <Div mb={5}>
        <Div mb={4} mt={4}>
          <Button
            py={1}
            px={3}
            fontSize={1}
            bg='white'
            border='1px solid'
            borderRight={viewMode === 'grid' ? '1px solid' : '0px solid'}
            borderColor={viewMode === 'grid' ? 'blue.4' : 'gray.6'}
            color={viewMode === 'grid' ? 'blue.4' : 'gray.6'}
            style={{ borderRadius: '3px 0px 0px 3px', cursor: 'pointer' }}
            fontWeight={700}
            onClick={() => {
              setViewMode('grid')
              localStorage.setItem('category-component-view-mode', 'grid')
            }}
          >
            Grid
          </Button>
          <Button
            py={1}
            px={3}
            fontSize={1}
            bg='white'
            border='1px solid'
            borderLeft={viewMode === 'grid' ? '0px solid' : '1px solid'}
            borderColor={viewMode === 'grid' ? 'gray.6' : 'blue.4'}
            color={viewMode === 'grid' ? 'gray.6' : 'blue.4'}
            style={{ borderRadius: '0px 3px 3px 0px', cursor: 'pointer' }}
            fontWeight={700}
            onClick={() => {
              setViewMode('table')
              localStorage.setItem('category-component-view-mode', 'table')
            }}
          >
            Table
          </Button>
        </Div>
        {viewMode === 'grid' ? (
          <ColorsGrid data={flatColors} />
        ) : (
          <ColorsTable data={flatColors} />
        )}
      </Div>
    </>
  )
}

export default Overview
