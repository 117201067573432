import React from 'react'
import Chroma from 'chroma-js'
import { Div, Span, Table, Thead, Th, Tr, Td } from '@cloudflare/elements'
import { variables as theme } from '@cloudflare/style-const'

const ColorsTable = ({ data }) => {
  return (
    <Div width={1}>
      <Table width={1} style={{ borderCollapse: 'collapse' }}>
        <Thead>
          <Tr fontSize={2}>
            <Th pb={2} textAlign='left'>
              Reference
            </Th>
            <Th pb={2} textAlign='left'>
              Hex
            </Th>
            <Th pb={2} textAlign='left'>
              RGB
            </Th>
            {/* <Th pb={2} textAlign="left">
              Reference
            </Th> */}
            <Th pb={2} textAlign='left'>
              Text
            </Th>
            <Th pb={2} textAlign='left'>
              Bg
            </Th>
            <Th pb={2} textAlign='left'>
              Accessible pairs
            </Th>
            <Th pb={2} textAlign='left' />
            <Th pb={2} textAlign='left'>
              Contrast w/ Black &amp; White
            </Th>
          </Tr>
        </Thead>
        {data.map(color => (
          <Tr key={color.color}>
            {/* Reference */}
            <Td py={2} width={160}>
              <Span fontSize={1} fontWeight={400} lineHeight={1.5}>
                {color.themeRef}
              </Span>
            </Td>
            {/* Hex code */}
            <Td px={0} width={80}>
              <Span fontSize={1} fontWeight={400} lineHeight={1.5}>
                {color.color}
              </Span>
            </Td>
            {/* RGB */}
            <Td px={0} width={80}>
              <Span fontSize={1} fontWeight={400} lineHeight={1.5}>
                {color.rgb}
              </Span>
            </Td>
            {/* Text example */}
            <Td py={2} color={color.color} width={48}>
              <Span fontWeight={600}>Aa</Span>
            </Td>
            {/* Background example */}
            <Td
              bg={color.color}
              width={48}
              borderRight='1px solid'
              borderColor='white'
            />
            {/* Accessible pairs */}
            <Td px={2} bg={color.color} width={128}>
              <Div
                display='flex'
                bg={color.color}
                justifyContent='start'
                width='auto'
              >
                {color.hue === 'red' && (
                  <>
                    {theme.colors['red'].map(item => (
                      <>
                        {Chroma.contrast(color.color, item) > 4.49 && (
                          <Div
                            fontWeight={600}
                            width='auto'
                            color={item}
                            mr={2}
                            title={item}
                          >
                            Aa
                          </Div>
                        )}
                      </>
                    ))}
                  </>
                )}
                {color.hue === 'orange' && (
                  <>
                    {theme.colors['orange'].map(item => (
                      <>
                        {Chroma.contrast(color.color, item) > 4.49 && (
                          <Div
                            fontWeight={600}
                            width='auto'
                            color={item}
                            mr={2}
                            title={item}
                          >
                            Aa
                          </Div>
                        )}
                      </>
                    ))}
                  </>
                )}
                {color.hue === 'blue' && (
                  <>
                    {theme.colors['blue'].map(item => (
                      <>
                        {Chroma.contrast(color.color, item) > 4.49 && (
                          <Div
                            fontWeight={700}
                            color={item}
                            mr={2}
                            title={item}
                          >
                            Aa
                          </Div>
                        )}
                      </>
                    ))}
                  </>
                )}
                {color.hue === 'indigo' && (
                  <>
                    {theme.colors['indigo'].map(item => (
                      <>
                        {Chroma.contrast(color.color, item) > 4.49 && (
                          <Div
                            fontWeight={600}
                            width='auto'
                            color={item}
                            mr={2}
                            title={item}
                          >
                            Aa
                          </Div>
                        )}
                      </>
                    ))}
                  </>
                )}
                {color.hue === 'violet' && (
                  <>
                    {theme.colors['violet'].map(item => (
                      <>
                        {Chroma.contrast(color.color, item) > 4.49 && (
                          <Div
                            fontWeight={600}
                            width='auto'
                            color={item}
                            mr={2}
                            title={item}
                          >
                            Aa
                          </Div>
                        )}
                      </>
                    ))}
                  </>
                )}
                {color.hue === 'gold' && (
                  <>
                    {theme.colors['gold'].map(item => (
                      <>
                        {Chroma.contrast(color.color, item) > 4.49 && (
                          <Div
                            fontWeight={600}
                            width='auto'
                            color={item}
                            mr={2}
                            title={item}
                          >
                            Aa
                          </Div>
                        )}
                      </>
                    ))}
                  </>
                )}
                {color.hue === 'green' && (
                  <>
                    {theme.colors['green'].map(item => (
                      <>
                        {Chroma.contrast(color.color, item) > 4.49 && (
                          <Div
                            fontWeight={600}
                            width='auto'
                            color={item}
                            mr={2}
                            title={item}
                          >
                            Aa
                          </Div>
                        )}
                      </>
                    ))}
                  </>
                )}
                {color.hue === 'cyan' && (
                  <>
                    {theme.colors['cyan'].map(item => (
                      <>
                        {Chroma.contrast(color.color, item) > 4.49 && (
                          <Div
                            fontWeight={600}
                            width='auto'
                            color={item}
                            mr={2}
                            title={item}
                          >
                            Aa
                          </Div>
                        )}
                      </>
                    ))}
                  </>
                )}
              </Div>
            </Td>
            {/* Contrast w/ black & white */}
            <Td width={128} bg={color.color}>
              <Div
                width='auto'
                alignItems='center'
                display='flex'
                justifyContent='start'
                fontWeight={600}
              >
                {Chroma.contrast(color.color, 'black') > 4.49 && (
                  <Div width='auto' color='black' mr={2}>
                    Aa
                  </Div>
                )}
                {theme.colors['gray'].map(item => (
                  <>
                    {Chroma.contrast(color.color, item) > 4.49 && (
                      <>
                        <Div width='auto' color={item} mr={2} title={item}>
                          Aa
                        </Div>
                      </>
                    )}
                  </>
                ))}
                {Chroma.contrast(color.color, 'white') > 4.49 && (
                  <Div color='white' width='auto' mr={2}>
                    Aa
                  </Div>
                )}
              </Div>
            </Td>

            <Td>
              <Div display='flex'>
                <Div width={64} display='flex' mr={3}>
                  <Span
                    width={3 / 4}
                    fontSize={1}
                    px={1}
                    py={1}
                    bg='black'
                    color={color.color}
                    fontWeight={600}
                  >
                    {color.contrastWithBlack}
                  </Span>
                  <Span
                    fontSize={1}
                    px={1}
                    py={1}
                    bg={color.color}
                    color='black'
                    fontWeight={600}
                  >
                    Aa
                  </Span>
                </Div>
                <Div width={64} display='flex'>
                  <Span
                    width={3 / 4}
                    fontSize={1}
                    px={1}
                    py={1}
                    bg={color.color}
                    color='white'
                    fontWeight={600}
                  >
                    {color.contrastWithWhite}
                  </Span>
                  <Span
                    fontSize={1}
                    px={1}
                    py={1}
                    bg='white'
                    color={color.color}
                    fontWeight={600}
                  >
                    Aa
                  </Span>
                </Div>
              </Div>
            </Td>
          </Tr>
        ))}
      </Table>
    </Div>
  )
}

export default ColorsTable
