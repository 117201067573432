/** @jsx jsx */
import { jsx } from 'theme-ui'
import { LogoCloud } from '@cloudflare/component-logo'
import { Icon } from '@cloudflare/component-icon'

const ProductLockup = ({ type, mr, size, product }) => {
  return (
    <div
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontSize: 4,
        fontFamily: 'Inter'
      }}
    >
      {type ? (
        <Icon
          type={type.toLowerCase()}
          mr={mr ? mr : ''}
          size={size}
          color='orange.6'
        />
      ) : (
        <LogoCloud width='64px' />
      )}
      <span sx={{ ml: 1, color: 'logotypeText'}}>
        <strong
          sx={{
            fontFamily: 'Inter'
          }}
        >
          Cloudflare
        </strong>{' '}
        {product}
      </span>
    </div>
  )
}

export default ProductLockup
