/** @jsx jsx */
import { jsx, Themed } from 'theme-ui'
import { H2 } from '@cloudflare/elements'
import React from 'react'

const Example = ({ title, children }) => {
  return (
    <React.Fragment>
      {title ? <Themed.h2>{title}</Themed.h2> : null}
      <div
        sx={{
          my: 3,
          border: '1px solid',
          borderColor: 'decorative',
          borderRadius: 1,
          p: 3
        }}
      >
        {children}
      </div>
    </React.Fragment>
  )
}

export default Example
