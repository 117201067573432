import CardBuilder from '@cloudflare/builder-card';
import { Button } from '@cloudflare/component-button';
import { Table, TableBody, TableRow, TableCell } from '@cloudflare/component-table';
import * as React from 'react';
export default {
  CardBuilder,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  React
};